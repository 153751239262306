.about {
  &__head-table {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    align-items: center;
    button {
      margin-right: 0;
      height: 45px;
    }
  }

  &__text-editor {
    margin: auto;
  }

  &__partners {
    width: 95%;

    &-body {
      height: 300px;
      border: 1px solid #ddd;
      margin: auto;
    }
  }

  &__stuff {
    display: flex;
    flex-direction: column;
    margin-bottom: 60px;

    &-body {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      width: 100%;
      border: 1px solid #ddd;
    }
  }

  &__plus {
    display: flex;
    width: 140px;
    margin: 40px auto 0;
    justify-content: center;
    align-items: center;
    height: 30px;
    color: #ffffff;
    background: #0d6efd;
    border-radius: 5px;
    box-shadow: 1px 5px 8px rgba(0, 0, 0, 0.3);
    padding: 10px;
    text-transform: uppercase;
  }
}
