.team-container-body {
  margin-top: 2rem;
  text-align: center;
  height: 600px;
  overflow-y: auto;
  min-width: 700px;

  &__info {
    display: flex;
    justify-content: center;
    &-item {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: #0d6efd;
      color: #fff;
      margin: 0 10px;
      cursor: help;
      position: relative;
      &-content {
        display: none;
        position: absolute;
        padding: 10px;
        width: 200px;
        border: 1px solid #999999;
        right: -11px;
        top: 30px;
        background: #fff;
        &:before {
          content: '';
          position: absolute;
          right: 7px;
          top: -8px;

          border-bottom: 8px solid #999999;
          border-left: 12px dotted transparent;
          border-right: 12px dotted transparent;
        }
      }
      &:hover {
        .team-container-body__info-item-content {
          display: block;
        }
      }
    }
  }

  table {
    border-collapse: collapse;
    width: 100%;
    margin-top: 10px;

    td,
    th {
      padding: 20px;
      text-align: center;
      border: 1px solid #ddd;
    }

    tr {
      border: 1px solid #ddd;
    }
  }
}

.icon {
  width: 40px;
  height: 40px;
  object-fit: cover;

  cursor: pointer;
}
