$small-desktop: 1200px;
$bigTablet: 980px;
$smallTablet: 640px;
$bigPhone: 480px;
$phone: 375px;
$smallPhone: 320px;
$mobilePortrait: 540px;

$tablet: 768px;
$laptop: 1024px;
$desktop: 1175px;

$break-phone: $tablet;
$break-tablet: $laptop;
$break-laptop: $desktop;

$devices: not-desktop phablet;

// Media queries6
// phone, tablet, desktop, not-desktop, not-phone

@mixin respond-to($media) {
  @if index($devices, $media) {
    @media only screen and (max-width: $break-tablet - 1) {
      @content;
    }
  } @else if $media == phone {
    @media only screen and (max-width: $break-phone - 1) {
      @content;
    }
  } @else if $media == phone-tablet {
    @media only screen and (max-width: $break-tablet - 1) {
      @content;
    }
  } @else if $media == tablet {
    @media only screen and (min-width: $break-phone) and (max-width: $break-laptop - 1) {
      @content;
    }
  } @else if $media == laptop {
    @media only screen and (min-width: $break-tablet) {
      @content;
    }
  } @else if $media == desktop {
    @media only screen and (min-width: $break-laptop) {
      @content;
    }
  } @else if $media == not-phone {
    @media only screen and (min-width: $break-phone) {
      @content;
    }
  } @else if $media == laptop-desktop {
    @media only screen and (min-width: $break-tablet) {
      @content;
    }
  } @else if $media == not-desktop {
    @media only screen and (max-width: $break-desktop - 1) {
      @content;
    }
  } @else {
    @warn "Incorrect @media: #{$media}";
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  margin: 0;
}

li {
  list-style-type: none;
}

ul {
  margin-left: 0;
  padding-left: 0;
}

a {
  text-decoration: none;
  color: #000;
}

* {
  color: #141851;
}

p {
  margin: 0;
}

.styleKey {
  cursor: pointer;
  user-select: none;

  &:hover {
    opacity: 0.8;
  }
}

.scrollElement {
  overflow: auto;

  &::-webkit-scrollbar {
    width: 10px;
    background-color: #f9f9fd;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #33333333;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background-color: #f9f9fd;
  }
}

.pagination {
  display: flex;
  justify-content: center;
}

.pagination > li {
  user-select: none;
  display: inline-block;
  padding-left: 0;
}

.pagination > li {
  list-style: none;
  //border: 0.9px solid;
}

.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  color: #2c689c;
  background-color: #fff;
  border: 1px solid #f2f2f2;
}

.pagination > li.active > a {
  outline: none;
  color: #fff;
  background-color: #0d6efd;
  border-radius: 3px;
}

/* Style the active class (and buttons on mouse-over) */
.pagination > li > a:hover {
  background-color: #0d6efd88;
  cursor: pointer;
  color: white;
}

.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
  padding: 0px;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
  display: none !important;
}

.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-bottom-right-radius: 4px;
  margin-right: 0;
  padding: 0px !important;
  border-top-right-radius: 4px;
  display: none !important;
}
