.links-management {
  width: 100%;
  display: block;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  &__field-row {
    position: relative;
    padding-bottom: 25px;

    .formControl.error {
      span {
        position: absolute;
        right: 0;
      }

      input {
        margin-top: 0;
      }
    }

    label {
      display: block;
      margin-bottom: 10px;
    }
  }

  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
