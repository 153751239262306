.error-page {
  width: 100vw;
  height: 100vh;
  display: flex;

  &__text {
    margin: auto;
    color: rgb(228, 44, 44);
    font-size: 30px;
    font-weight: 300;
  }
}
