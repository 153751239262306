.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  min-height: 100%;
  min-width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}
.modal {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  min-width: 480px;
  max-width: 80%;
  background-color: #fff;
  border-radius: 10px;

  &__close {
    position: absolute;
    z-index: 131213;
    top: 50px;
    right: 80px;
  }

  &__close-btn {
    width: 40px;
    height: 40px;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__close-icon {
    cursor: pointer;
    width: 20px;
    height: 20px;
    fill: #fff;

    &:hover {
      fill: #eba110;
    }
  }
}
