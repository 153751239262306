.partners {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  height: 48px;
  border-bottom: 1px solid #f0f0f0;

  &:nth-child(6) {
    border-bottom: 0;
  }

  &-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-name {
    width: 15%;
    text-align: center;
    padding: 5px 0;
  }

  &__img-item {
    width: 5%;
  }

  &__name {
    width: 35%;
  }

  &__site {
    width: 35%;
  }

  &-logo {
    width: auto;
    max-width: 25px;
    max-height: 25px;
  }

  &-banner {
    width: auto;
    max-width: 25px;
    max-height: 25px;
  }

  &__button {
    width: 10%;

    &-img {
      width: 25px;
    }
  }
}
