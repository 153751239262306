.home {
  display: flex;
  margin: 30px 0;

  &__left-container {
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  &__button-save {
    width: 150px;
    background: #0d6efd;
    color: #f2f2f2;
    text-align: center;
    padding: 5px 0;
    margin: 15px 0;
    border-radius: 5px;
  }

  &__body {
    width: 70%;
    max-width: 800px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: auto;
    border: 1px solid #999999;
    padding: 30px;

    &__item {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30%;
      min-width: 150px;
      height: 150px;
      border: solid 2.5px #888;
      margin: 1%;
      border-radius: 15px;

      &-onChange {
        position: relative;

        &:after {
          position: absolute;
          z-index: 3;
          left: 10px;
          top: 10px;
          content: 'X';
          display: flex;
          justify-content: center;
          align-items: center;
          width: 20px;
          height: 20px;
          background: #888888;
          border: 2px solid #33f;
          border-radius: 30px;
          color: white;
          font-size: 14px;
        }
      }
    }

    &__image {
      width: 70%;
    }
  }
}
