.form-category {
  width: 80%;
  display: block;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  &__error {
    color: #ff0000;
    margin: 0 auto;
  }

  &__label {
    margin: 0;
    padding: 0;
    text-transform: uppercase;
  }

  &__picker {
    margin-bottom: 20px;
  }

  &__input {
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    padding: 5px 10px;
    border: 1px solid rgb(72, 71, 71);
    border-radius: 4px;
    overflow: hidden;
    outline: none;
    margin-bottom: 15px;
    margin-top: 5px;

    &::placeholder {
      text-transform: uppercase;
    }
  }

  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  &__button {
    border: none;
    padding: 10px 35px;
    margin: 10px;
    border-radius: 25px;
    color: white;
    background-color: rgb(82, 103, 88);
    cursor: pointer;
    outline: none;
    font-size: 18px;
  }
}

.cancel {
  background: #d8d8d8;
}
