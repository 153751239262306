.handler {
  position: fixed;
  z-index: 999999999;
  top: 80%;
  right: 0;
  max-height: 50%;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom-in;
  animation-duration: 0.6s;
  &-message {
    padding: 30px;
  }

  &__error {
    background: #ff444366;
    border: red;
    color: red;
  }

  &__success {
    background: #43ff4366;
    border: rgb(85, 255, 0);
    color: rgb(255, 255, 255);
  }
}
