.leftPanel {
  display: flex;
  width: 300px;
  min-height: 100%;
  margin-right: 35px;
  background: #000;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  &__logo {
    height: 120px;
    width: 132px;
  }
  &-break {
    margin-bottom: 30px;
  }

  &__item {
    background: #999;
    margin: 15px 0;
    text-align: center;
    padding: 20px 0;
    color: #f2f2f2;
    &:hover {
      opacity: 0.7;
    }
  }

  &__logout {
    cursor: pointer;
    background: rgb(91, 91, 91);
    margin: 50px 0;
    padding: 25px 0;
    display: flex;
    justify-content: space-between;
    justify-content: center;

    &-icon {
      width: 20px;
      height: 20px;
      margin-left: 20px;
    }
    &-text {
      color: #f2f2f2;
    }
    &:hover {
      opacity: 0.7;
    }
  }

  ul {
    width: 100%;
    margin-top: 50px;
  }
}
