.select-container-body {
  margin-top: 2rem;
  text-align: center;
  max-height: 400px;
  min-width: 700px;
  overflow-y: auto;

  table {
    border-collapse: collapse;
    width: 100%;

    td,
    th {
      padding: 20px;
      text-align: center;
      border: 1px solid #ddd;
    }
    tr {
      border: 1px solid #ddd;
    }
  }
}

.select-players__checkbox {
  width: 20px;
  height: 20px;
}

.icon {
  width: 30px;
  height: 30px;

  cursor: pointer;
}
