.galleryMedia {
  width: 600px;
  height: 30%;

  background: #fff;

  &__input {
    margin-bottom: 20px;
  }

  &__body {
    justify-content: space-between;
    padding: 20px;
  }

  &__input-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }

  &__container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    overflow: auto;
    border: 1px solid #33333333;
    border-radius: 3px;
    box-sizing: border-box;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.3);

    &::-webkit-scrollbar {
      width: 10px;
      background-color: #f9f9fd;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #33333333;
    }

    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      background-color: #f9f9fd;
    }
  }

  &__key-container {
    display: flex;
    justify-content: space-around;
  }

  &__button {
    width: 40%;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: #fff;
    box-shadow: 1px 5px 8px rgba(0, 0, 0, 0.3);

    &:hover {
      opacity: 0.8;
    }

    &-cancel {
      background-color: #888888;
    }

    &-save {
      background-color: #0d6efd;
    }
    &-disabled {
      background-color: #888888;
    }
  }
}
