.categories {
  width: 100%;
  &__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__button {
    width: 300px;
    border: none;
    padding: 20px;
    margin: 10px;
    border-radius: 25px;
    color: white;
    background-color: rgb(82, 103, 88);
    cursor: pointer;
    outline: none;
    font-size: 18px;
  }
}
