.button {
  height: auto;
  display: flex;
  margin: 10px;
  padding: 10px 35px;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  background: #0d6efd;
  border-radius: 5px;
  text-transform: uppercase;
  cursor: pointer;
  outline: none;
  &:hover {
    opacity: 0.8;
  }
}

.button-disabled {
  background: #666666;
}

.button-remove {
  background: #ff5252;
  outline: none;
}
