.form-team-container {
  max-height: 90vh;
  overflow-y: auto;

  .form-team {
    width: 900px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin: 0;
    padding: 0;

    &__error {
      color: #ff0000;
      align-self: flex-start;
      margin-bottom: 10px;
    }

    &__opponent {
      margin-bottom: 20px;
      &-checkbox {
        width: 20px;
        height: 20px;
        margin-right: 20px;
      }
      display: flex;
    }

    &__text-content {
      width: 100%;
      display: flex;
      justify-content: space-evenly;

      &-first {
        width: 45%;
      }
      &-second {
        width: 45%;
      }
    }

    &__label {
      margin: 0;
      padding: 0;
      text-transform: uppercase;
    }

    &__input {
      box-sizing: border-box;
      height: 40px;
      width: 50%;
      padding: 5px 10px;
      border: 1px solid rgb(72, 71, 71);
      border-radius: 4px;
      overflow: hidden;
      outline: none;
      margin-bottom: 15px;
      margin-top: 5px;
    }

    &__actions {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    &__textarea {
      box-sizing: border-box;
      width: 100%;
      height: 130px;
      padding: 5px 10px;
      outline: none;
      resize: none;
      cursor: auto;
    }

    &__button {
      border: none;
      padding: 10px 35px;
      margin: 10px;
      border-radius: 25px;
      color: white;
      background-color: rgb(82, 103, 88);
      cursor: pointer;
      outline: none;
      font-size: 18px;
    }
  }

  .cancel {
    background: #d8d8d8;
  }
}
