.videoList {
  width: 95%;
  text-align: start;
  font-size: 25px;
  font-weight: bold;
  margin: 15px 0;
  padding: 10px 0;
  border-bottom: 1px solid #999;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .button {
    font-size: 17px;
  }
  &__item {
    width: 95%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
  }

  &__video {
    &-list {
      width: 100%;
      height: 297px;
      border: 1px solid #ddd;
    }

    &-container {
      display: flex;
      border-bottom: 1px solid #ddd;

      &:nth-child(7) {
        border-bottom: none;
      }

      &-link {
        width: 50%;
      }
    }

    &-data {
      cursor: pointer;
      width: 15%;
      display: flex;
      align-items: center;
      &:hover {
        color: rgb(158, 162, 182);
        fill: rgb(158, 162, 182);
      }
    }

    &-item {
      width: 14%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-right: 1px solid #ddd;

      &_deleted {
        width: 35px;
        height: 35px;
        margin: 5px 0;
      }

      &_edit {
        width: 30px;
        height: 30px;
      }

      &_play {
        width: 40px;
        height: 25px;
      }

      &:last-child {
        border-right: none;
      }

      &-link {
        width: 45%;
      }

      &-icon {
        // fill: #141851;
        height: 10px;
      }

      &-edit {
        width: 30px;
        height: 30px;
      }
      &-off {
        width: 30px;
        height: 30px;
        opacity: 0.5;
      }
    }
  }
}
