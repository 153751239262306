.tagsSelector {
  display: flex;
  width: 400px;
  overflow: auto;
  border: 1px solid #33333333;
  margin: 20px 0;
  border-radius: 3px;
  box-sizing: border-box;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.3);

  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: #f9f9fd;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #33333333;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    background-color: #f9f9fd;
  }

  &__item {
    margin: 1px;
    padding: 1px;
    border: 3px solid transparent;

    &-active {
      position: relative;
      border: 3px solid #3333ff;
      margin: 1px;
      padding: 1px;
    }
  }

  &__icon {
    width: 25px;
    height: 25px;
  }
}
