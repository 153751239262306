.confirm {
  width: 500px;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  &-title {
    margin-bottom: 40px;
  }
  &-buttons {
    display: flex;
  }
}
