.categories-item {
  padding: 10px;
  margin: 10px;
  width: 150px;
  border: 1px solid #f2f2f2;

  &__controls {
    display: flex;
    flex-direction: column;
    height: 70px;
    justify-content: space-between;
  }

  .categories-item__inner-wrap {
    display: flex;
    justify-content: space-between;

    .categories-item__img {
      width: 100px;
      height: 150px;

      position: relative;
      &-category {
        width: 100px;
        height: 100px;
        object-fit: contain;
      }

      &-remove {
        width: 30px;
        height: 30px;

        cursor: pointer;
      }
    }
  }

  &__name {
    font-weight: bold;
  }
  &__color {
    display: inline-block;
    width: 20px;
    height: 10px;
  }
}
