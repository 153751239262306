.login-form {
  width: 300px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10%;

  &__label {
    width: 100%;

    p {
      margin: 5px 0;
    }
  }

  &__input {
    border: solid 1px #ced4da;
    width: 100%;
    border-radius: 5px;
    padding: 5px;
    box-sizing: border-box;
    outline: none;
    margin-bottom: 10px;
  }

  &__button {
    border: none;
    width: 100%;
    margin: 20px 0;
    padding: 5px;
    color: #fff;
    outline: none;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    &-active {
      background: #0d6efd;
    }

    &-disabled {
      background: #777;
    }
  }

  &__error {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 50px;
    background: #f2222222;

    &-text {
      color: red;
      padding: 0 10px;
      width: 100%;
    }
  }
}
