.partnerCreator {
  background: #fff;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  min-width: 300px;

  &__error {
    color: #ff0000;
    margin-bottom: 10px;
  }
  &__item {
    width: 100%;
    margin: 10px 0;
  }

  &__button-container {
    display: flex;
    flex-direction: row;
  }
  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    height: 35px;
    margin: 10px;
    background: #3333ff;
    color: white;
    border-radius: 5px;
  }
}

.stuff-language {
  color: red;
}
