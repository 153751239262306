.team-item {
  padding: 10px;
  margin: 10px;
  width: 195px;
  display: flex;
  flex-direction: column;
  border: 1px solid #f2f2f2;

  &__game {
    position: absolute;
    width: 30px;
    height: 30px;

    &-icon {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .team-item__inner-wrap {
    display: flex;
    justify-content: space-between;

    .team-item__img {
      position: relative;
      width: 100%;
      height: 150px;
      display: flex;
      justify-content: center;
      align-items: center;

      &-game {
        height: 120px;
        width: 120px;
        object-fit: contain;
      }
    }

    .team-item__controls {
      display: flex;
      flex-direction: column;
      align-items: center;

      &-remove {
        width: 30px;
        height: 30px;
        margin-bottom: 10px;

        cursor: pointer;
      }

      &-edit {
        width: 30px;
        height: 30px;

        cursor: pointer;
      }
    }
  }

  &__name {
    font-weight: bold;
  }
  &__position {
    font-weight: bold;
  }
}
