.formNews {
  background: #fff;
  padding: 30px;
  width: 80%;
  max-height: 40%;
  max-height: 80vh;
  overflow: auto;

  .se-anchor-preview-form {
    display: none !important;
  }

  li {
    list-style-type: inherit;
  }
  &__label {
    margin-bottom: 10px;
  }

  &__select {
    box-sizing: border-box;
    height: 40px;
    width: 200px;
    padding: 5px 10px;
    border: 1px solid rgb(72, 71, 71);
    border-radius: 4px;
    overflow: hidden;
    outline: none;
    margin-bottom: 15px;
    margin-top: 5px;

    &-container {
      display: flex;
      flex-direction: column;
    }
  }

  &__title {
    display: flex;
    margin: 30px 0;
  }

  &__news {
    border: 1px solid #f2f2f2;
    box-shadow: 1px 5px 8px rgba(0, 0, 0, 0.3);
  }

  &__isBannerVisible {
    display: flex;
    align-items: center;
    p {
      margin: 0 5px;
    }
  }

  &__buttons {
    display: flex;
  }

  &__images {
    display: flex;
    align-items: center;
    justify-content: center;

    &-item {
      margin: 0 30px 30px 0;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
