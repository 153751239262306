.error-handler {
  position: fixed;
  z-index: 999999999;
  top: 80%;
  right: 0;
  max-height: 50%;
  background: #ff444366;
  border: red;
  color: red;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom-in;
  animation-duration: 0.6s;
  &-message {
    padding: 30px;
  }
}
