.gallery {
  background: #fff;
  height: 85vh;
  display: flex;
  flex-direction: row-reverse;
  width: 90vw;

  &__input-container {
    width: 400px;
  }

  &__upload-file {
    border-right: 2px solid #888888;
    padding: 30px;
  }
  &__button {
    display: flex;
    flex-direction: row;
    &-save {
      margin: 20px 20px 0 0;
      padding: 10px 20px;
      background: #3333ff;
      width: 60px;
      text-align: center;
      border-radius: 5px;
      text-transform: uppercase;
      color: #fff;
    }
    &-disabled {
      margin: 20px 20px 0 0;
      padding: 10px 20px;
      background: #888888;
      width: 60px;
      text-align: center;
      border-radius: 5px;
      text-transform: uppercase;
      color: #fff;
    }
  }
  &__upload {
    display: flex;
    flex-direction: row;
  }

  &__container {
    padding: 25px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    box-sizing: border-box;
  }

  &__image {
    height: 100px;
    width: 100%;
    object-fit: cover;
  }

  &__item {
    width: 17%;
    min-width: 116px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid #f2f2f2;
    padding: 10px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    margin: 30px 2%;
    &-key {
      width: 95%;
      margin: 5px auto;
      padding: 2px;
      background: #0d6efd;
      border-radius: 3px;
      color: #fff;
      text-align: center;
      border: 1px solid #f3f3f3;
    }

    &-delete {
      background: #ff3333;
    }
  }
}
