.uploaderVideos {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  // height: 330px;
  padding: 20px;

  &__default-twitch {
    &-image {
      margin-top: 10px;
      width: 100px;
      height: auto;
    }

    &-container {
      display: flex;
      flex-direction: column;
    }
  }

  &__inputs {
    display: flex;
    width: 100%;
    justify-content: space-between;

    &__container {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      label {
        margin-top: 10px;
      }
    }

    &__type {
      margin: auto;
      label {
        display: flex;
        height: 20px;
        align-items: center;

        input {
          margin: 10px;
        }
      }
    }
  }
  &__error {
    background: #ff000050;
    padding: 5px 30px;

    border: 1px solid #f20;
    &-text {
      color: #f20;
    }
  }
  &__key {
    display: flex;
    width: 100%;
    justify-content: space-around;

    &__button {
      width: 40%;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      color: #fff;
      box-shadow: 1px 5px 8px rgba(0, 0, 0, 0.3);

      &:hover {
        opacity: 0.8;
      }

      &-cancel {
        background-color: #888888;
      }

      &-save {
        background-color: #0d6efd;
      }
    }
  }
}

.react-select {
  &__indicator {
    cursor: pointer;

    &-separator {
      width: 0px !important;
    }
  }

  &__control {
    border-width: 0 !important;
    max-width: 398px !important;

    &--is-focused {
      border: none !important;
      box-shadow: none !important;
    }
  }

  &__multi-value__remove {
    cursor: pointer;

    &:hover {
      background-color: #0d6efd !important;
      color: #fff !important;

      svg {
        fill: #fff;
      }
    }
  }
}

.basic-multi-select {
  border: 1px solid #484747;
  border-radius: 4px;
}
