.input {
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  padding: 16px 10px;
  border: 1px solid rgb(72, 71, 71);
  border-radius: 4px;
  overflow: hidden;
  outline: none;

  &::placeholder {
    text-transform: uppercase;
  }
}
