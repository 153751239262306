.partnerCreator {
  &-container {
    max-height: 90vh;
    overflow-y: auto;
  }
  background: #fff;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  min-width: 300px;

  &__item {
    width: 100%;
    margin: 10px 0;
  }
}
