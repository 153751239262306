.form-event-container {
  max-height: 90vh;
  overflow-y: auto;
  padding: 0 30px;
}

.form-event {
  display: flex;
  flex-direction: column;
  justify-content: start;
  margin: 0;
  padding: 0;
  position: relative &__error {
    color: #ff0000;
    align-self: flex-start;
    margin-bottom: 10px;
  }

  &__text-content {
    width: 100%;
    display: flex;
    justify-content: space-evenly;

    &-first {
      width: 45%;
    }
    &-second {
      width: 45%;
    }
  }

  &__label {
    margin: 0;
    padding: 0;
    text-transform: uppercase;
  }

  &__input {
    box-sizing: border-box;
    height: 40px;
    width: 100%;
    padding: 5px 10px;
    border: 1px solid rgb(72, 71, 71);
    border-radius: 4px;
    overflow: hidden;
    outline: none;
    margin-bottom: 15px;
    margin-top: 5px;
  }

  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  &__textarea {
    box-sizing: border-box;
    width: 100%;
    height: 130px;
    padding: 5px 10px;
    outline: none;
    resize: none;
    cursor: auto;
  }

  &__button {
    border: none;
    padding: 10px 35px;
    margin: 10px;
    border-radius: 25px;
    color: white;
    background-color: rgb(82, 103, 88);
    cursor: pointer;
    outline: none;
    font-size: 18px;
  }

  &__opponent-form {
    position: absolute;
    z-index: 12;
    top: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100%;
    background-color: #fff;
    border-radius: 10px;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 30px;
    &-close {
      align-self: flex-start;
      margin-bottom: auto;
      position: absolute;
      right: 20px;
      top: 20px;
      width: 20px;
      height: 20px;
      color: black;
      cursor: pointer;

      &:hover {
        fill: coral;
      }
    }

    &-head {
      display: flex;
      justify-content: space-between;
    }
  }
}

.cancel {
  background: #fffdfd;
}
