.comment {
  width: 65vw;
  height: 75vh;

  &__absent {
    width: 40%;
    background: #fff22248;
    text-align: center;
    padding: 33px 0;
    border: 1px solid #fff222;
    margin: 20% auto auto;
  }

  &__description {
    background: #0d6efd;
    width: 20%;
    padding: 5px 20px;

    a {
      color: #fff;
      box-sizing: border-box;
    }
  }

  &__item {
    display: flex;
    justify-content: space-around;
    height: 100px;
    margin-top: 30px;
    border-bottom: 1px solid #2f2f2f;
    padding-bottom: 10px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
  }

  &__container {
    width: 15%;

    &-info {
      display: flex;

      div {
        width: 50%;
      }
    }
  }

  &__lest-element {
    width: 20%;

    &-like {
      div {
        width: 100%;
        text-align: center;
      }
    }
  }

  &__deleted {
    width: 80%;
    margin: 10px auto;
    height: 30px;
    padding: 0 5px;
    background: #ff3333;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    border-radius: 4px;
  }

  &__text {
    width: 55%;
    resize: none;
  }
}
