.formControl.error input,
.formControl.error textarea,
.formControl.error select {
  border: solid red 1px;
  margin: 0;
  margin-top: 5px;
  border-radius: 4px;
}

.formControl.error span {
  color: red;
}
