.container {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 10px 0;
  word-break: break-all;
}

.localization-tabs__head {
  display: flex;
  padding: 10px 0;
  display: flex;
  align-items: center;
  &-info {
    margin-left: 10px;
  }
}

.localization-tabs {
  &__icon {
    width: 15px;
    fill: rgb(248, 104, 104);
  }
}

.tabs__information {
  padding: 10px 0;
  position: absolute;
  left: -30px;
  top: 0;
}
.tabs {
  padding: 5px;
  text-align: center;
  width: 50%;
  background: rgba(128, 128, 128, 0.075);
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.274);
  box-sizing: content-box;
  position: relative;
  outline: none;
}
.tabs:not(:last-child) {
  border-right: 1px solid rgba(0, 0, 0, 0.274);
}

.active-tabs {
  background: white;
  border-bottom: 1px solid transparent;
}

.active-tabs::before {
  content: '';
  display: block;
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% + 2px);
  height: 5px;
  background: rgb(226, 196, 26);
}

button {
  border: none;
}
.content-tabs {
  flex-grow: 1;
}
.content {
  background: white;
  padding: 8px;
  height: 100%;
  display: none;
}
.content h2 {
  padding: 0px 0 5px 0px;
}
.content hr {
  height: 2px;
  background: #222;
  margin-bottom: 5px;
}

.active-content {
  display: block;
}
