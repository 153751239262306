.media {
  padding-bottom: 10%;

  &-sort {
    display: flex;
    align-items: center;
    button {
      margin-top: 0;
      margin-bottom: 0;
    }
    input {
      // height: 30px;
      font-size: 17px;
    }
    &__select {
      max-width: 240px;
      margin-right: 50px;
    }

    &__form {
      display: flex;
      align-items: center;
    }

    &__button {
      &-text {
        color: #fff;
        margin-right: 7px;
      }
    }

    &__icon {
      width: 20px;
      fill: #fff;
    }
  }
  &__item {
    width: 95%;
  }
  &__paragraph {
    width: 95%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      text-align: start;
      font-size: 25px;
      font-weight: bold;
      text-transform: capitalize;
    }
  }

  &__gallery {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;

    width: 100%;
    height: 500px;
    border: solid 1px #ddd;
    border-radius: 5px;
    margin-top: 30px;

    &-button {
      align-self: center;
    }

    &__item {
      margin: 30px 1.4%;
      width: 17%;
      padding-bottom: 10px;
      border: solid 1px #ddd;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &__key {
      width: 80%;
      margin: 10px auto 0;
      padding: 5px;
      background: #ff3333;
      box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.3);
      border-radius: 2px;

      p {
        text-align: center;
        color: #f2f2f2;
      }

      &-open {
        background: #3333ff;
      }
    }

    &__image {
      width: 120px;
      height: 120px;
      object-fit: contain;

      &-container {
        margin: 15px auto;
        width: 100%;
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 10px;
      }
    }

    &__data {
      font-size: 13px;
      text-align: center;
    }

    &__name {
      font-size: 14px;
      text-align: left;
      margin-left: 10px;
    }

    &__title {
      margin-left: 10px;
      font-size: 15px;
      font-weight: bold;
    }
  }
}
