.fileLoader {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: flex-start;

  &__label {
    margin: 10px 5px;

    &-close-image {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;

      &:after {
        position: absolute;
        top: 1px;
        left: 2px;
        content: 'X';
        color: #f33;
        background: #9f9f9f9f;
        font-size: 11px;
        padding: 2px 5px;
        border: #f33 solid 2.5px;
        border-radius: 1000px;
      }
    }
  }

  &__input {
    display: none;
  }

  &__image {
    width: 45px;
    max-height: 45px;
  }

  &__upload-image {
    width: 85px;
    max-height: 85px;
  }
  &__text {
    font-size: 10px;
  }

  &__image-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    max-height: 220px;
    overflow-y: auto;
  }
}
