.news {
  margin-bottom: 100px;
  width: 95%;
  &-news-list {
    padding: 30px;
  }

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #f2f2f2;
    margin: 10px 0;
    padding: 10px 5px;
    box-sizing: border-box;

    &-name {
      width: 60%;
    }

    &-lang {
      font-weight: bold;
      margin-right: 10px;
    }
    &-img {
      width: 20px;
      margin: auto;
    }
  }
}

.lang-selector {
  display: flex;

  label {
    display: flex;
    align-items: center;
    margin: 0 10px;

    p {
      margin: 0 5px;
    }
  }
}
