.teams {
  width: 96%;
  &__head {
    display: flex;
    justify-content: space-between;
    button {
      height: 45px;
    }
  }
}
