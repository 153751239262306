.shop-links-table {
  &__order-buttons {
    display: flex;
    justify-content: space-around;

    button {
      cursor: pointer;
      font-size: 30px;
      padding: 10px;
    }
  }
}
