.table-controls {
}

.empty-comments-list {
  opacity: 0.3;
}

.react-paginate {
  display: flex;
  justify-content: center;
}

.event {
  width: 99%;

  &__button {
    width: 300px;
    border: none;
    padding: 20px;
    margin: 10px;
    border-radius: 25px;
    color: white;
    background-color: rgb(82, 103, 88);
    cursor: pointer;
    outline: none;
    font-size: 18px;
  }

  &-icon {
    width: 30px;
    height: 30px;
    object-fit: cover;
  }
}

.event-container {
  text-align: center;
  max-height: 700px;
  overflow-y: auto;
  min-width: 700px;
  border: 1px solid #ddd;

  .sort {
    cursor: pointer;

    &:hover {
      color: rgb(248, 48, 48);
    }
  }

  .table {
    border-collapse: collapse;
    width: 100%;
    &__heading {
      text-align: left;
      padding: 20px;
      text-align: center;
    }

    th {
      border: 1px solid #ddd;
    }

    td,
    tr {
      max-width: 200px;
      border: 1px solid #ddd;
      padding: 5px;
    }
  }
}

.icon {
  width: 30px;
  height: 30px;
  cursor: pointer;
}
